import Carousel from 'react-bootstrap/Carousel';
import './Carousel.css';

function UncontrolledExample() {
  return (
    <Carousel id='myCarousel' variant="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="images/booth1.png"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Photo Booth</h3>
          <p>Weddings, birthdays and corporate events</p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="images/banner3.png"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>Candy Cart</h3>
          <p>
            Use our hand made candy cart for desserts, chai, bangles and more
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default UncontrolledExample;