import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Category from './category';
import reportWebVitals from './reportWebVitals';
import Cookies from 'universal-cookie';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import ReactGA from "react-ga4";

ReactGA.initialize("G-CX2KYY5GSV");
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
 <App />
 </Router>
 {/*     <Router>
    <Routes>
  <Route path="/" element={<App />} />
  <Route path="/category" element={<Category />} />
  </Routes>

</Router> */}
</React.StrictMode>


/*  <React.StrictMode>
    <App />
  </React.StrictMode>  */
);

const cookies = new Cookies();
cookies.set("api-token", "12345", {   path:"/"});

//Initialize GA4


const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
