import "./Pricing.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Pricing() {

    const handleClick = () => {
        const element = document.getElementById("contact");
        if (element) {
          element.scrollIntoView();
        // ref.current?.scrollIntoView({behavior: 'smooth'});
        }
          };

    return (
        <section className="pricing section-bg" data-aos="fade-up">
            <div className="container">
    
            <div className="section-title">
                <h2>Pricing</h2>
                {/* <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> */}
            </div>
    
           <Row className="no-gutters">
            <Col lg={4} className="box">
            <h3>Basic</h3>
                <h4>$200<span>2 hours</span></h4>
                <ul>
                    <li><i className="bx bx-check"></i> Unlimited photo sessions</li>
                    <li><i className="bx bx-check"></i> Black or White Pillow Backdrop</li>
                    <li><i className="bx bx-check"></i> Custom Photo Template</li>
                    <li><i className="bx bx-check"></i> Social Media Sharing</li>
                    <li><i className="bx bx-check"></i> On Site Attendant</li>
                </ul>
                <a onClick={handleClick} className="get-started-btn">Get Started</a>
            </Col>

            <Col lg={4} className="box featured">
            <h3>Enhanced</h3>
                <h4>$300<span>3 hours</span></h4>
                <ul>
                <li><i className="bx bx-check"></i> Unlimited photo sessions</li>
                    <li><i className="bx bx-check"></i> Black or White Pillow Backdrop</li>
                    <li><i className="bx bx-check"></i> Custom Photo Template</li>
                    <li><i className="bx bx-check"></i> Social Sharing</li>
                    <li><i className="bx bx-check"></i> On Site Attendant</li>
                    <li><i className="bx bx-check"></i> 4x6 Prints</li>
                </ul>
                <a onClick={handleClick} className="get-started-btn">Get Started</a>
            </Col>
            <Col lg={4} className="box">
            <h3>Premium</h3>
                <h4>$400<span>4 hours</span></h4>
                <ul>
                <li><i className="bx bx-check"></i> Unlimited photo sessions</li>
                    <li><i className="bx bx-check"></i> Black or White Pillow Backdrop</li>
                    <li><i className="bx bx-check"></i> Custom Photo Template</li>
                    <li><i className="bx bx-check"></i> Social Sharing</li>
                    <li><i className="bx bx-check"></i> On Site Attendant</li>
                    <li><i className="bx bx-check"></i> 4x6 Prints</li>
                </ul>
                <a onClick={handleClick} className="get-started-btn">Get Started</a>
            </Col>

            
           </Row>
    


    
            </div>
      </section>
    );
    }

export default Pricing;