import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./CategoryDetailsBooth.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link, useLocation, useParams} from 'react-router-dom';
import {useState, useEffect} from 'react';
import Image from 'react-bootstrap/Image';
import Category from "../category";
import Pricing from "./Pricing";

type CategoryItem = {
  text: string;
  img_src: string;
  description: string;
}

type Catergory = {
  title: string;
  id: string;
  products: CategoryItem[];
}

function CategoryDetailsBooth() {
  const [data,setData]=useState<Catergory[] | undefined>(undefined);

  const location = useLocation();
  //const propsData = location.state as any;
  

  useEffect(()=>{
    const element = document.getElementById("service");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
  },[])


  return (

<div>
      <section className="features">
         <Container>

        <div className="section-title">
          <h2>Photo Booth</h2>
          <p>Capture Memories, Create Moments<br />Are you ready to take your event to the next level? Look no further than Red Oak Decor Photobooths! Our cutting-edge photo booths are the life of the party, providing endless fun and unforgettable memories for you and your guests.</p>
        </div>
        <Row>
          <Col md={4} className="pt-4 product-box">
              <img src="/images/photobooth2.jpg" className="img-fluid" alt=""></img>
          </Col>
          <Col md={7} className="pt-4">
          <h3>Premium Quality</h3>
              <p className="fst-italic">
              Our state-of-the-art photo booths are equipped with high-resolution cameras and professional lighting to ensure every photo is picture-perfect.
              </p>
              <ul>
                <li><i className="bi bi-check"></i> High Quality DLSR Camera</li>
                <li><i className="bi bi-check"></i> Studio Flash</li>
              </ul>
          </Col>
        </Row>
        <Row>
        <Col md={7} className="pt-4">
          <h3>Customization</h3>
              <p className="fst-italic">
              Personalize your experience with your individuality. From elegant weddings to lively birthday parties, we have options to suit every occasion.
               </p>
              <ul>
                <li><i className="bi bi-check"></i> Personalized messages</li>
                <li><i className="bi bi-check"></i> <a href="/booth/print/">Custom Templates</a></li>
              </ul>
          </Col>
          <Col md={3} className="pt-4 product-box">
              <img src="/images/customization.png" className="img-fluid" alt=""></img>
          </Col>
        </Row>

        <Row>
          <Col md={3} className="pt-4 product-box">
              <img src="/images/photostack.jpg" className="img-fluid" alt=""></img>
          </Col>
          <Col md={7} className="pt-4">
          <h3>Instant Prints</h3>
              <p className="fst-italic">
              Watch as your photos come to life in seconds! With our lightning-fast printers, you and your guests can take home beautiful prints to cherish forever.
              </p>
              <ul>
                <li><i className="bi bi-check"></i> Professional prints</li>
                <li><i className="bi bi-check"></i> Custom layout templates</li>
              </ul>
          </Col>
        </Row>
        <Row>
        <Col md={7} className="pt-4">
        <h3>Digital Sharing</h3>
              <p className="fst-italic">
              Share the fun with friends and family in real-time! Our booths offer instant social media sharing, allowing you to upload photos directly to your favorite platforms.
               </p>
              <ul>
                <li><i className="bi bi-check"></i> Text message</li>
                <li><i className="bi bi-check"></i> QR Codes</li>
                <li><i className="bi bi-check"></i> Social Sharinng</li>
              </ul>
          </Col>
          <Col md={3} className="pt-4 product-box">
              <img src="/images/sharing.jpg" className="img-fluid" alt=""></img>
          </Col>

        </Row>
        <Row>
          <Col md={3} className="pt-4 product-box">
              <img src="/images/fullsetup.jpg" className="img-fluid" alt=""></img>
          </Col>
          <Col md={7} className="pt-4">
          <h3>Professional Service</h3>
              <p className="fst-italic">
              Our friendly attendants will be there every step of the way to ensure your event runs smoothly. From setup to teardown, we handle everything so you can focus on making memories.
              </p>
              <ul>
                <li><i className="bi bi-check"></i> On site attendant</li>
                <li><i className="bi bi-check"></i> Setup and takedown</li>
              </ul>
          </Col>
        </Row>
        <Row>
        <Col md={7} className="pt-4">
          <h3>Props</h3>
              <p className="fst-italic">
             No part is complete with props for a fun photo experience.
               </p>
              <ul>
                <li><i className="bi bi-check"></i> Custom Props</li>
                <li><i className="bi bi-check"></i> Black and white backgrounds</li>
              </ul>
          </Col>
          <Col md={3} className="pt-4 product-box">
              <img src="/images/props.jpg" className="img-fluid" alt=""></img>
          </Col>
        </Row>


        </Container>
    </section>
<Pricing></Pricing>
</div>
  );
}



export default CategoryDetailsBooth;
